//Apply slider to top section
$(document).ready(function(){
	$('.matchup-select-1').slick({
    slide: '.slide-content',
    infinite: false,
    adaptiveHeight: true,
    draggable: false,
    prevArrow: '<button type="button" class="slick-prev button">Edit</button>',
    nextArrow: '<button type="button" class="slick-next button float-right">Continue</button>',
    lazyLoad: 'progressive',
    appendArrows: $('.slide-arrows1')
  });
});

//Apply slider to bottom section
$(document).ready(function(){
	$('.matchup-select-2').slick({
    slide: '.slide-content',
    infinite: false,
    adaptiveHeight: true,
    draggable: false,
    prevArrow: '<button type="button" class="slick-prev button">Edit</button>',
    nextArrow: '<button type="button" class="slick-next button float-right">Continue</button>',
    lazyLoad: 'progressive',
    appendArrows: $('.slide-arrows2')
  });
});

//Filter toggle
$('.filter-type a').click(function(){
	$(this).toggleClass('active');
	$('.filter-panel').toggleClass('fullview');
});

//Filter Full View Close
$('.fullview-close').click(function(){
	$('.filter-type a').removeClass('active');
	$('.filter-panel').toggleClass('fullview');
});

//List view vs Grid View - Top Section
$('.filter-options-1 .view-type').click(function(){
	$(this).siblings().removeClass('active');
	$(this).addClass('active');
	if ($('.matchup-select-1').hasClass('list-view')){
		$('.matchup-select-1').removeClass('list-view')
		$('.matchup-select-1').addClass('grid-view');
		}
	else {
		$('.matchup-select-1').removeClass('grid-view')
		$('.matchup-select-1').addClass('list-view');
	};
	$(window).trigger('resize');
});
//List view vs Grid View - Bottom Section
$('.filter-options-2 .view-type').click(function(){
	$(this).siblings().removeClass('active');
	$(this).addClass('active');
	if ($('.matchup-select-2').hasClass('list-view')){
		$('.matchup-select-2').removeClass('list-view')
		$('.matchup-select-2').addClass('grid-view');
		}
	else {
		$('.matchup-select-2').removeClass('grid-view')
		$('.matchup-select-2').addClass('list-view');
	};
	$(window).trigger('resize');
});

$('.field-list li').click(function(){
	$(this).siblings().removeClass('active');
	$(this).addClass('active');
});

$('.filter-row .mobile-toggle').click(function(){
	$(this).children('i').toggleClass('fa-angle-up');
	$(this).siblings('ul').toggleClass('active');
});


//Select Team/Player
$('.list-item .item-container').click(function(){
	$(this).toggleClass('selected');
	$('.matchup-select-1 .item-container').each(function() {
		if ($(this).hasClass('selected')) {
			$('.matchup-select-1 .slick-next').addClass('active');
		}
	});
});


$(document).foundation();
